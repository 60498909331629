@import '../../../variables.scss';

.questSzkolenieGrzybiarzaMushroomBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questSzkolenieGrzybiarzaMushroomImgBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.questSzkolenieGrzybiarzaMushroomImg {
    width: 10rem;
    height: 10rem;
    margin: 0.3rem;
    border-radius: 0.3rem;
    box-sizing: border-box;

    @include boxShadow;
}

.questSzkolenieGrzybiarzaMushroomImg.selected {
    border: $light-accent-color 0.4rem solid;
}

.questSzkolenieGrzybiarzaMushroomImg.correct {
    border: $correct-color 0.4rem solid;
}

.questSzkolenieGrzybiarzaMushroomImg.incorrect {
    border: $incorrect-color 0.4rem solid;
}

.questSzkolenieGrzybiarzaMushroomImg.selectable {
    cursor: pointer;
}

