@import '../../../../variables.scss';

.questMistrzFillGap {
    background-color: $very-light-accent-color;
    font-family: 'Lato';
    font-style: italic;
    color: #797979;
    padding: 0.7rem;
    border-radius: 0.2rem;
    margin: 0 1rem;
    text-align: center;

    @media only screen and (min-width: 400px) {
        max-width: 30vw;
    }
}