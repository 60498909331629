@import '../../../variables.scss';

.editorBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.yaeEmoji {
    width: 9rem;
    @include emojiShadow;
    margin-bottom: 0.7rem;
}

.editorInfo1 {
    font-family: 'Lato';
    font-size: 1.2rem;
    margin: 1rem 1rem 0.2rem;
    text-align: center;
}

.editorInfo2 {
    font-family: 'Lato';
    font-size: 1.2rem;
    font-weight: 600;
}

.editorTextArea {
    margin: 1rem;
    width: 80vw;
    font-size: 1.2rem;

    @media only screen and (min-width: 400px) {
        width: 40vw;
    }
}

.modalExampleText {
    white-space: pre-wrap;
    font-style: italic;
    color: $lighter-text-color;
}