@import '../../variables.scss';

.redeemRewardBody {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 0 1rem;
    align-items: center;
}

.rewardEmoji {
    width: 10rem;
    @media only screen and (min-width: 400px) {
        width: 20vw;
    }
}

.rewardText {
    @include headerText;
}

.rewardScanner {
    width: 80vw;

    @media only screen and (min-width: 400px) {
        width: 30vw;
    }
}