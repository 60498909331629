@import '../../../variables.scss';

.questJanKenPonBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questJanKenPonButtonBox {
    margin-top: 1rem;
}