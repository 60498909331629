@import '../../../variables.scss';

.questTestPoszukiwaczaCompletedEmojiBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.questTestPoszukiwaczaCompletedEmoji {
    width: 10rem;
    @include emojiShadow;
    margin-left: 1rem;
    @media only screen and (max-width: 768px) {
        width: 9rem;
    }
}

.questTestPoszukiwaczaCompletedSpeechBubble {
    position: relative;
    margin-top: 1rem;
    background-color: $light-text-color;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: $light-text-color;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        padding: 1rem;
    }

    @include emojiShadow;
}

.questTestPoszukiwaczaCompletedSpeechBubbleText {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 400;
    white-space: pre-wrap;
    color: $regular-text-color;
    font-family: 'Lato';
    cursor: pointer;
    @media only screen and (min-width: 768px) {
        font-size: 1rem;
        line-height: auto;
        padding: 0 0.5rem;
    }
}

.questTestPoszukiwaczaCompletedSpeechBubble::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -10px;
    border-width: 8px 0 12px 20px;
    border-color: transparent transparent transparent $light-text-color;
    border-style: solid;

    @media only screen and (min-width: 768px) {
        top: 2rem;
    }
}

.questTestPoszukiwaczaCompletedButtonBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.questTestPoszukiwaczaCompletedButton {
    margin-right: 1rem;
}