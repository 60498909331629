@import '../../../variables.scss';

.testPoszukiwaczaBody {
    font-family: 'Lato';
    color: $regular-text-color;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testPoszukiwaczaQuestionTitle {
    margin-top: 1rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
}

.testPoszukiwaczaSubmitButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}