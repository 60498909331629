@import '../../variables.scss';

.questPerlySangonomiyiBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.questPerly {
    width: 100%;
}