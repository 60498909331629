@import '../../variables.scss';

.wholeBottomBar {
    width: 100%;
}

.bottomBarDisclaimerBody {
    background-color: $top-bar-bottom-background-color;
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
    color: $regular-text-color;
    text-align: center;
}
.bottomBarBody {
    background-color: $bottom-bar-background-color;
    color: $bottom-bar-text-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media screen and (max-width: 540px) {
        justify-content: space-around;
    }
    padding: 0.2rem 0.4rem;
}

.bottomBarLinkBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: $bottom-bar-text-color;
        text-decoration: none;
        font-weight: 200;
        font-size: 0.75rem;
    }
}

.bottomBarIcon {
    margin-right: 0.2rem;
    font-size: 1rem;
    @media screen and (min-width: 540px) {
        margin-left: 1rem;
    }
}