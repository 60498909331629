@import '../../variables.scss';

.questMenuButtonBody {
    width: 80vw;
    display: flex;
    flex-direction: row;
    font-family: 'Neuton';
    padding: 0.4rem;
    border-radius: 0.2rem;
    margin: 0.5rem 0;
    @include boxShadow;
}

.quest-failed {
    background-color: $questFailed;
    cursor: default;
}

.quest-inProgress {
    background-color: $questInProgress;
    cursor: pointer;
}

.quest-completed {
    background-color: $questCompleted;
    cursor: default;
}

.questMenuButtonBodyFrame {
    display: flex;
    flex-direction: row;
    border: 1px #ffffff54 solid;
    width: 100%;
    border-radius: 0.2rem;
    padding: 0.4rem;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 6rem;
}

.quest-Liyue {
    background-image: url('../../assets/graphics/ui/questMenuLiyue.webp');
}

.quest-Sumeru {
    background-image: url('../../assets/graphics/ui/questMenuSumeru.webp');
}

.quest-Monstadt {
    background-image: url('../../assets/graphics/ui/questMenuMonstadt.webp');
}

.quest-Inazuma {
    background-image: url('../../assets/graphics/ui/questMenuInazuma.webp');
}

.quest-Fontaine {
    background-image: url('../../assets/graphics/ui/questMenuFontaine.webp');
}

.questMenuButtonTitle {
    color: $light-text-color;
    font-size: 1.5rem;
    margin-top: -0.5rem;
    @include whiteTextShadow;
    @media only screen and (max-width: 375px) {
        font-size: 1.2rem;
    }
}

.questMenuButtonLocationText {
    color: rgba(255, 255, 255, 0.473);
    font-size: 1rem;
    margin-left: 0.2rem;
}

.questMenuButtonLocationBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.questMenuQuestIcon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    z-index: 0;
}

.questMenuLocationIcon {
    width: 1rem;
    opacity: 80%;
}

.questMenuQuestIconBox {
    position: relative;
}