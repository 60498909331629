@import '../../../../variables.scss';

.questPerlyFailedBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questPerlyFailedKokomi {
    width: 12rem;
    margin: 1rem 0 0;
}

.questPerlyFailedText {
    @include headerText;
}