@import '../../../variables.scss';

.questAbyssOrderMageBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questAbyssOrderMageImg {
    width: 35vw;
    @include emojiShadow;
    margin-bottom: 0.5rem;

    @media only screen and (min-width: 768px) {
        width: 15vw;
    }
}

.questAbyssOrderMageInput {
    margin: 1rem 0;
    width: 50%;
}