@import '../../../variables.scss';

.questJanKenPonScoreBoardBody {
    background-color: $score-board-color;
    border-radius: 3px;
    width: 20rem;
    padding: 0.5rem;
    @include boxShadow;

    @media only screen and (min-width: 768px) {
        width: 40vw;
    }
}

.questJanKenPonScoreBoardInnerBody {
    border: 1px solid $light-text-color;
    padding: 0.3rem;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.questJanKenPonScoreBoardEmoji {
    width: 5rem;
}

.questJanKenPonScoreBoardScoreBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    font-family: 'Neuton';
    font-weight: 600;
    color: $regular-text-color;
}

.questJanKenPonScoreBoardScoreNumbersBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 4rem;
    font-size: 2rem;

    @media only screen and (min-width: 768px) {
        width: 7vw;
    }
}

.questJanKenPonScoreBoardScoreNumber {
    color: $light-accent-color;
}