@import'../../variables.scss';

.menuBody {
    height: 100%;
    background-image: $background-gradient, url('../../assets/graphics/ui/background.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.scaramoucheBox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.scaramouche {
    width: 10rem;
    @include emojiShadow;
    @media only screen and (min-width: 768px){
        margin-right: 1rem;
    }
}

.speech-bubble {
    position: relative;
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.308);
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: $light-text-color;
    @media only screen and (min-width: 768px){
        padding: 1rem;
    }
    @include emojiShadow;
  }

  .speech-bubble-text {
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    white-space: pre-wrap;
    line-height: 1.2rem;
    font-family: 'Lato';
  }
  
  .speech-bubble::before {
    @include emojiShadow;
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 8px 31px 12px 0;
    border-color: transparent rgba(255, 255, 255, 0.308) transparent transparent;
    right: 100%;
    top: 4rem;
    @media only screen and (min-width: 768px){
        top: 2rem;
    }
  }