@import '../../variables.scss';

.aboutUsBody {
    color: $regular-text-color;
    padding: 1rem;
    white-space: pre-wrap;
    text-indent: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lato';
    text-align: justify;
    @media only screen and (min-width: 450px) {
        font-size: 1.2rem;
        width: 1000px;
    }
    @media only screen and (max-width: 800px) and (min-width: 450px) {
        width: 90vw;
    }
}

.projectLogo {
    width: 80vw;

    @media only screen and (min-width: 900px) {
        width: 50vw;
    }
}

.aboutUsImg {
    width: 90vw;
    @media only screen and (min-width: 600px) {
        width: 60vw;
    }
}

.whereCanYouFindUs {
    @include headerText;
}

.socialMediaIcon {
    width: 3rem;
}

.socialMediaBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.allSocialMedia {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -1rem;
}