@import '../../variables.scss';

.menuItem {
    position: relative;
    margin-bottom: 1rem;
}

.menuButtonBody {
    background-image: url('../../assets/graphics/ui/menuItemBackground.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 80vw;
    color: $menu-item-text-color;
    text-align: center;
    max-width: 20rem;
    @include emojiShadow;
}

.menuButtonTitle {
    font-size: 1.5rem;
    font-family: 'Neuton';
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 0.13rem solid $menu-item-text-color;
    width: 12rem;
    margin-top: -0.5rem;
}

.menuButtonDescription {
    font-size: 1rem;
    font-family: 'Neuton';
    margin: 0.2rem 3rem 0;
    line-height: 0.7rem;
}

.menuEmojiLeft {
    position: absolute;
    bottom: 0;
    width: 6rem;
    left: -2rem;
    @include emojiShadow;

    @media only screen and (min-width: 820px) and (max-width: 1000px){
        left: -4vw;
        width: 6.5rem;
        bottom: 1.5vh;
    }

    @media only screen and (min-width: 900px) and (max-width: 1000px){
        left: 0;
        width: 5rem;
        bottom: 0;
    }

    @media only screen and (min-width: 1000px){
        left: -2rem;
        width: 6rem;
        bottom: 1vh;
    }
}

.menuEmojiRight {
    position: absolute;
    bottom: 0;
    width: 6rem;
    right: -2rem;
    @include emojiShadow;

    @media only screen and (min-width: 820px) and (max-width: 1000px){
        right: -4vw;
        width: 6.5rem;
        bottom: 1.5vh;
    }

    @media only screen and (min-width: 900px) and (max-width: 1000px){
        right: 0;
        width: 5rem;
        bottom: 0;
    }

    @media only screen and (min-width: 1000px){
        right: -2rem;
        width: 6rem;
        bottom: 1vh;
    }
}