@import '../../../../variables.scss';

.memberPhotoBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0.5rem;
    @media only screen and (max-width: 800px) {
        margin: 0 0.25rem;
    }
}

.memberPhotoText {
    opacity: 80%;
    color: white;
    margin-top: -2rem;
    text-shadow: 0px 0px 14px rgba(66, 68, 90, 1), 0px 0px 14px rgba(66, 68, 90, 1), 0px 0px 14px rgba(66, 68, 90, 1), 0px 0px 14px rgba(66, 68, 90, 1);
    font-family: 'Lato';
    font-weight: 600;
    font-size: 1.5rem;
    @media only screen and (max-width: 450px) {
        margin-top: -1.5rem;
        font-weight: normal;
        font-size: 1rem;
    }
}

.memberPhoto {
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 27px -19px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 27px -19px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 27px -19px rgba(66, 68, 90, 1);
    @media only screen and (max-width: 800px) {
        width: 30vw;
    }
}