@import '../../../variables.scss';

.questWrittenInTheStarsInfoBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questWrittenInTheStarsInfoSpeechBubbleBox {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.questWrittenInTheStarsInfoSpeechBubble {
    position: relative;
    background-color: $light-text-color;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: $light-text-color;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        padding: 1rem;
        width: 60vw;
    }

    @include emojiShadow;
}

.questWrittenInTheStarsInfoSpeechBubble::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -10px;
    border-width: 8px 0 12px 20px;
    border-color: transparent transparent transparent $light-text-color;
    border-style: solid;

    @media only screen and (min-width: 768px) {
        top: 2rem;
    }
}

.questWrittenInTheStarsInfoSpeechBubbleText {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 400;
    white-space: pre-wrap;
    color: $regular-text-color;
    font-family: 'Lato';
    cursor: pointer;
    @media only screen and (min-width: 768px) {
        font-size: 1rem;
        line-height: auto;
        padding: 0 0.5rem;
    }
}

.questWrittenInTheStarsInfoEmoji {
    width: 10rem;

    @media only screen and (max-width: 415px) {
        height: 9rem;
    }
}