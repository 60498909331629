@import '../../../variables.scss';

.poemBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $regular-text-color;
} 
.poem {
    text-align: center;
    margin-bottom: -0.7rem;
}

.poemVerse {
    @include poemVerse;
}

.poemAnswerVerses {
    display: flex;
    flex-direction: column;
}

.poemAnswers {
    margin: 1.5rem;
    
}

.poemIncorrect {
    color: $incorrect-color;
}

.poemCorrect {
    color: $correct-color;
}