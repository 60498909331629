@import '../../../variables.scss';

.questWymianaJezykowaCompletedBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questWymianaJezykowaCompletedEmoji {
    width: 25rem;

    @media only screen and (max-width: 400px) {
        width: 20rem;
    }
}

.questWymianaJezykowaCompletedText {
    @include headerText;
}