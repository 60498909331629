@import '../../../variables.scss';

.questSummaryConstellationHeader {
    font-family: 'Lato';
    color: $regular-text-color;
}

.questSummaryConstellationBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0;
}

.questSummaryConstellationImg {
    width: 40vw;
    border-radius: 1rem;
    @include emojiShadow;

    @media only screen and (min-width: 768px) {
        width: 10vw;
    }
}

.questSummaryConstellationTitle {
    @include headerText;
    margin-left: 1rem;
}

.questSummaryCorrectAnswers {
    text-align: center;
}