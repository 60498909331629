@import '../../../variables.scss';

.testPoszukiwaczaQuestionAnswersBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testPoszukiwaczaQuestionAnswerIncorrect {
    color: $incorrect-color;
    font-weight: 500;
}

.testPoszukiwaczaQuestionAnswerCorrect {
    color: $correct-color;
    font-weight: 500;
}

.testPoszukiwaczaQuestionAnswerBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.testPoszukiwaczaQuestionAnswerCorrectIcon {
    color: $correct-color;
    margin-left: 0.5rem;
}

.testPoszukiwaczaQuestionAnswerIncorrectIcon {
    color: $incorrect-color;
    margin-left: 0.5rem;
}

.testPoszukiwaczaQuestionAnswerButton {
    margin-top: 1rem;
}