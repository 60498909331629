$background-color: #E8E8E8;

$accent-color: #354D71;
$light-accent-color: #5B758D;
$regular-text-color: #525252;
$lighter-text-color: #979797;
$light-text-color: #ffffff;
$correct-color: #5db148;
$dark-correct-color: #4d8a3e;
$incorrect-color: #cc4242;
$dark-incorrect-color: #a83f3f;
$very-light-accent-color: #b3c7db;

$background-gradient: linear-gradient(180deg, rgba(140, 152, 163, 0.3) 0%, rgba(179, 154, 92, 0.5) 100%);
$menu-item-text-color: #E8C88F;

$inactive-button-background-color: #818181;
$active-button-background-color: $accent-color;
$button-text-color: $light-text-color;

$top-bar-background-color: $light-accent-color;
$top-bar-bottom-background-color: #c9c9c9;
$top-bar-text-color: $light-text-color;

$bottom-bar-background-color: $accent-color;
$bottom-bar-text-color: rgba(255, 255, 255, 0.5);

$questFailed: #838383;
$questInProgress: $light-accent-color;
$questCompleted: $accent-color;

$score-board-color: #d9d9d9;
$janKenPon-symbols-bgcolor: #DDDDDD;
$janKenPon-Paimon-bgColor: #CDCDCD;

@mixin emojiShadow {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@mixin whiteTextShadow {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

@mixin boxShadow {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

@mixin headerText {
    font-size: 1.5rem;
    font-family: 'Lato';
    color: $accent-color;
    text-align: center;
    font-weight: 600;
}

@mixin poemVerse {
    font-family: 'Lato';
    color: $regular-text-color;
    font-style: italic;
    font-size: 1.3rem;
}