@import '../../../../variables.scss';

.janKenPonSymbolBoxWithText {
    padding: 0.5rem;
    background-color: $janKenPon-symbols-bgcolor;
    margin: 0.5rem 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.janKenPonSymbolBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 20rem;
}

.janKenPonChooseSymbolText {
    @include headerText;
    color: $regular-text-color
}

.janKenPonSymbol {
    width: 6rem;
}

.janKenPonSymbol-selectable {
    cursor: pointer;
}