@import '../../../../variables.scss';

.questPerlyCompletedBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questPerlyCompletedPearls {
    width: 10rem;
}

.questPerlyCompletedKokomi {
    width: 12rem;
    margin: 1rem 0;
}

.questPerlyCompletedText {
    @include headerText;
}