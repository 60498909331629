@import '../../variables.scss';

.topBarBody {
    background-color: $top-bar-background-color;
    color: $top-bar-text-color;
    border-bottom: 6px solid $top-bar-bottom-background-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.55rem 0.55rem;
}

.topBarLogo {
    max-height: 2rem;
}

.topBarIconsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topBarIcon {
    margin-left: 0.5rem;;
}

.link {
    color: $top-bar-text-color;
    text-decoration: none;
}