@import '../../../variables.scss';

.questTestPoszukiwaczaInstructionsEmojiBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.questTestPoszukiwaczaInstructionsEmoji {
    width: 8rem;
    margin-right: 1rem;
}

.questTestPoszukiwaczaInstructionsEmojiSpeechBubble {
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: $light-text-color;

    @media only screen and (min-width: 768px) {
        padding: 1rem;
    }

    @media only screen and (min-width: 390px) and (max-width: 395px) {
        padding: 0.4rem;
        width: 55vw;
    }

    @media only screen and (max-width: 390px) {
        width: 50vw;
        padding: 0 0.7rem;
    }

    @media only screen and (min-width: 8768px) {
        width: 50vw;
        padding: 0 0.7rem;
    }

    @include emojiShadow;
}

.questTestPoszukiwaczaInstructionsEmojiSpeechBubble::before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 21px 12px 0;
    border-color: transparent white transparent transparent;
    right: 99%;
    top: 2rem;

    @media only screen and (min-width: 768px) {
        top: 2rem;
    }
}

.questTestPoszukiwaczaInstructions-speech-bubble-text {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 400;
    white-space: pre-wrap;
    color: $regular-text-color;
    font-family: 'Lato';
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        font-size: 1rem;
        line-height: auto;
        padding: 0 0.5rem;
    }
}

.questTestPoszukiwaczaInstructionsStartGameBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
}