@import '../../../../../variables.scss';

.questJanKenPonCompletedBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.questJanKenPonCompletedEmoji {
    width: 16rem;
    margin-top: 1rem;
}

.questJanKenPonCompletedText {
    @include headerText;
    margin: 0.5rem 1.5rem 1rem;
}