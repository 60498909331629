@import '../../../../../variables.scss';

.questJanKenPonFailedBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.questJanKenPonFailedEmoji {
    margin-top: 1rem;

    @media only screen and (max-width: 415px) {
        width: 12rem;
    }
}

.questJanKenPonFailedText {
    @include headerText;
    margin: 0 1rem 1rem;
}