@import '../../../variables.scss';

.qrCodeReaderBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.questPerlyQR {
    width: 100%;
}

.qrCodeReaderButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.qrCodeReaderButton {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
}

.qrCodeReaderInput {
    margin-top: 1rem;
}