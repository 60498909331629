@import '../../../variables.scss';

.questKuCzciInfoBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questKuCzci-speech-bubble-text {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 400;
    white-space: pre-wrap;
    color: $regular-text-color;
    font-family: 'Lato';
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        font-size: 1rem;
        line-height: auto;
        padding: 0 0.5rem;
    }
}

.questKuCzciEmoji {
    width: 10rem;
    @include emojiShadow;

    @media only screen and (max-width: 390px) {
        width: 7rem;
    }

    @media only screen and (min-width: 390px) and (max-width: 395px) {
        width: 8rem;
    }
}

.questListSpeechBubbleRaiden {
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: $light-text-color;
    width: 45vw;
    margin-left: 0.5rem;

    @media only screen and (min-width: 768px) {
        padding: 1rem;
    }

    @media only screen and (min-width: 390px) and (max-width: 395px) {
        padding: 0.4rem;
        width: 55vw;
    }

    @media only screen and (max-width: 390px) {
        width: 50vw;
        padding: 0 0.7rem;
    }

    @media only screen and (min-width: 768px) {
        width: 50vw;
        padding: 0 1rem;
        margin-left: 1rem;
    }

    @include emojiShadow;
}

.questListSpeechBubbleRaiden::before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 6px 21px 12px 0;
    border-color: transparent white transparent transparent;
    right: 99%;
    top: 2rem;
}

.questListSpeechBubbleYae {
    position: relative;
    margin-right: 1rem;
    margin-left: 0.5rem;
    background-color: $light-text-color;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: $light-text-color;
    cursor: pointer;
    width: 45vw;

    @media only screen and (max-width: 390px) {
        width: 60vw;
        padding: 0.2rem;
    }

    @media only screen and (min-width: 390px) and (max-width: 395px) {
        padding: 0.4rem;
        width: 50vw;
    }

    @media only screen and (min-width: 768px) {
        padding: 1rem;
        width: 50vw;
        margin-top: 2rem;
    }

    @include emojiShadow;
}

.questListSpeechBubbleYae::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 99%;
    margin-top: -10px;
    border-width: 8px 0 12px 20px;
    border-color: transparent transparent transparent $light-text-color;
    border-style: solid;

    @media only screen and (min-width: 768px) {
        top: 2rem;
    }
}

.questKuCzciSpeechBubbleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1rem 1rem 0;
}

.questKuCzciTanukiEmoji {
    @include emojiShadow;
    width: 12rem;
    margin-bottom: 0.6rem;
}