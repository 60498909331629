@import "../../../../variables.scss";

.janKenPonPaimonBoardBody {
    background-color: $janKenPon-Paimon-bgColor;
    width: 100vw;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.janKenPonPaimonBoardEmoji {
    height: 12rem;

    @media only screen and (max-width: 400px){
        height: 8rem;
    }
}