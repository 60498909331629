@import '../../variables.scss';

.genericStandQuestBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.genericStandQuestBubbleBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 -2rem 0.5rem;
}

.genericStandQuestBubbleEmoji {
    width: 12rem;
    @include emojiShadow;

    @media only screen and (max-width: 390px) {
        width: 10rem;
    }
}

.genericStandQuestBubbleSpeechBubble {
    position: relative;
    margin-right: 0.5rem;
    background-color: $light-text-color;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: $light-text-color;
    cursor: pointer;

    @media only screen and (max-width: 390px) {
        width: 60vw;
        padding: 0.2rem;
    }

    @media only screen and (min-width: 390px) and (max-width: 395px) {
        padding: 0.4rem;
        width: 55vw;
    }

    @media only screen and (min-width: 768px) {
        padding: 1rem;
        width: 50vw;
        margin-top: 2rem;
    }

    @include emojiShadow;
}

.genericStandQuestBubbleSpeechBubble::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 99%;
    margin-top: -10px;
    border-width: 8px 0 12px 20px;
    border-color: transparent transparent transparent $light-text-color;
    border-style: solid;

    @media only screen and (min-width: 768px) {
        top: 2rem;
    }
}

.genericStandQuestBubbleSpeechBubbleText {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 400;
    white-space: pre-wrap;
    color: $regular-text-color;
    font-family: 'Lato';
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        font-size: 1rem;
        line-height: auto;
        padding: 0 0.5rem;
    }
}

.genericStandQuestButtonBox {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.genericStandQuestButton {
    margin-right: 1rem;
}

.genericStandQuestPasswordButton {
    margin-top: 1rem;
}