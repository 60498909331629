@import '../../variables.scss';

.introductionBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem;
    font-family: 'Lato';
    @media only screen and (min-width: 768px) {
        padding-top: 4rem;
    }
}

.introductionEmoji {
    width: 10rem;
}

.introductionHeaderText {
    @include headerText;
}

.introductionInput {
    width: 80%;
    margin-bottom: 1rem;
}