@import '../../variables.scss';

.calendarScreenBody {
    font-family: 'Lato';
    color: $regular-text-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 1rem 0.5rem;
    @media only screen and (min-width: 600px){
        margin-top: 15vh;
    }
}

.calendarHeader {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    @media only screen and (min-width: 600px){
        margin-bottom: 5vh;
    }
}

.calendarSteps {
    @media only screen and (max-width: 600px){
        margin-left: 2rem;
    }
}

.calendarEmoji {
    width: 12rem;
    @media only screen and (max-height: 670px){
        width: 8rem;
    }
    @media only screen and (min-width: 600px){
        margin-top: 1rem;
    }
    @include emojiShadow;
}