@import '../../variables.scss';

.questKuCzciBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.questKuCzciFailedBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questKuCzciEmoji {
    width: 7rem;
}

.questKuCzciFailedText {
    @include headerText
}