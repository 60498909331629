@import '../../variables.scss';

.languageBody {
    color: $regular-text-color;
    font-family: 'Lato';
    margin-top: 30vh;
}
.languageText {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.5rem;;
}

.languageFlags {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.flag {
    border-radius: 20rem;
    width: 5rem;
    height: 5rem;
    margin: 0 1rem;
    @include emojiShadow;
    box-sizing: border-box;
    cursor: pointer;
}

.selectedLanguageFlag {
    border: 0.2rem $light-accent-color solid;
}

.languageChangeAlert {
    position: fixed;
    top: 4rem;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 9999;
    color: $correct-color;
    width: 90%;
}