@import '../../../variables.scss';

.questWrittenInTheStarsConstellationBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.questWrittenInTheStarsConstellationImg {
    width: 85vw;
    margin-top: 1rem;
    border-radius: 1rem;
    @include emojiShadow;

    @media only screen and (min-width: 768px) {
        width: 30vw;
    }
}