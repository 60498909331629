@import './variables.scss';

.appBody {
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: visible;
}

.link {
    text-decoration: none;
}