@import '../../variables.scss';

.questAbyssOrderBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.questAbyssOrderSpeechBubbleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.questAbyssOrderSpeechBubble {
    position: relative;
    background-color: $light-text-color;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    color: $light-text-color;
    width: 40vw;
    margin-right: 1rem;

    @media only screen and (min-width: 768px) {
        padding: 1rem;
        width: 60vw;
    }

    @include emojiShadow;
}

.questAbyssOrderSpeechBubble::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -10px;
    border-width: 8px 0 12px 21px;
    border-color: transparent transparent transparent $light-text-color;
    border-style: solid;

    @media only screen and (min-width: 768px) {
        top: 2rem;
    }
}

.questAbyssOrderSpeechBubbleText {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 400;
    white-space: pre-wrap;
    color: $regular-text-color;
    font-family: 'Lato';
    cursor: pointer;
    @media only screen and (min-width: 768px) {
        font-size: 1rem;
        line-height: auto;
        padding: 0 0.5rem;
    }
}

.questAbyssOrderEmoji {
    height: 8rem;

    @media only screen and (max-width: 415px) {
        height: 8rem;
    }
}

.questAbyssOrderMageRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
    justify-content: center;
}

.questAbyssOrderResultsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}