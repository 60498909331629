@import '../../../variables.scss';

.previewBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.previewInfo {
    @include headerText;
    margin: 1rem;
}

.previewEmoji {
    width: 12rem;
}

.poemLineBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Lato';
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
}

.poemLine {
    font-style: italic;
}

.syllablesCount {
    background-color: $score-board-color;
    border-radius: 0.2rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin-right: 1rem;
    @include boxShadow;
}

.correct {
    color: $dark-correct-color;
}

.incorrect {
    color: $dark-incorrect-color;
}

.previewButtonsBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.goBackButton {
    margin-right: 1rem;
}